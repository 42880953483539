import {AbstractControl, ValidationErrors} from '@angular/forms';
import {isEmpty} from '@matchsource/utils';

export const OVER_WEIGHT_KEY = 'over_weight';

export const overWeightValidator =
  (key = OVER_WEIGHT_KEY) =>
  (control: AbstractControl): ValidationErrors | null => {
    const {value} = control;

    const isValid = !isEmpty(value) && new RegExp(/[1-9]\d{3}/i).test(value);

    return isValid ? {[key]: {value: control.value}} : null;
  };
